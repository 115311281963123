/* eslint-disable react/prop-types */
import React from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";

import { useTennisContext } from "../../../scoreboard-app/components/Tennis/TennisContext";
import ToggleButtons from "../../ToggleButtons";
import PickleballModalSection from "../PickleballModalSection";
import TennisModalSection from "../TennisModalSection";
import GenerateCodeButton from "./components/MSGenerateCodeButton";
import useTranslations from "../../../contexts/useTranslations";

function TennisSettingsModal() {
  const t = useTranslations();

  const {
    setShowSettingsModal,
    gameModeLegs,
    setGameModeLegs,
    firstToGames,
    setFirstToGames,
    tiebreakLegs,
    setTiebreakLegs,
    isManualScores,
    setIsManualScores,
    initiateDB,
    tennisVariant,
    setTennisVariant,
    firstToPoints,
    setFirstToPoints,
    totalNumberOfGames,
    setTotalNumberOfGames,
    resetScoreboard,
    isGoldenPoint,
    setIsGoldenPoint,
    updateFB,
  } = useTennisContext();

  const radios = [
    { name: t("noun:tennis"), value: "0" },
    { name: "Ping Pong", value: "1" },
    { name: t("noun:pickle-ball"), value: "2" },
  ];

  const Tennis = () => {
    return (
      <>
        <Row>
          {tennisVariant !== "3" ? (
            <ToggleButtons
              variant={tennisVariant}
              setVariant={setTennisVariant}
              radios={radios}
            />
          ) : null}
        </Row>
        {tennisVariant === "0" ? (
          <TennisModalSection
            gameModeLegs={gameModeLegs}
            setGameModeLegs={setGameModeLegs}
            firstToGames={firstToGames}
            setFirstToGames={setFirstToGames}
            tiebreakLegs={tiebreakLegs}
            setTiebreakLegs={setTiebreakLegs}
          />
        ) : tennisVariant === "3" ? (
          <Padel
            firstToGames={firstToGames}
            setFirstToGames={setFirstToGames}
            totalNumberOfGames={totalNumberOfGames}
            setTotalNumberOfGames={setTotalNumberOfGames}
            isGoldenPoint={isGoldenPoint}
            setIsGoldenPoint={setIsGoldenPoint}
            updateFB={updateFB}
          />
        ) : (
          <PickleballModalSection
            firstToPoints={firstToPoints}
            setFirstToPoints={setFirstToPoints}
            totalNumberOfGames={totalNumberOfGames}
            setTotalNumberOfGames={setTotalNumberOfGames}
          />
        )}
        <Row>
          <Col className="d-flex justify-content-center">
            <GenerateCodeButton initiateDB={initiateDB} />
          </Col>

          <Col className="d-flex justify-content-center">
            {tennisVariant === "0" ? (
              <Button
                className="mt-0 w-100"
                variant="success"
                onClick={() => {
                  setIsManualScores((manualScore) => {
                    updateFB({ isManualScores: !manualScore });
                    return !manualScore;
                  });
                }}
              >
                {!isManualScores
                  ? t("noun:manual-scores")
                  : t("noun:automatic-scores")}
              </Button>
            ) : (
              <Button
                className="mt-0 w-100"
                variant="success"
                onClick={() => resetScoreboard()}
              >
                {"Reset Scoreboard"}
              </Button>
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div>
      <Modal.Dialog
        centered
        style={{
          top: -60,
          left: 0,
          zIndex: 400,
          position: "absolute",
          minWidth: 530,
          maxWidth: 530,
          minHeight: 15,
        }}
      >
        <Modal.Body style={{ minHeight: 250 }}>
          <h5 className="text-center">{t("noun:settings")}</h5>
          <Container>
            <Tennis />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          {/* <Row className="fixed-bottom"> */}
          <Button
            style={{ float: "right", width: "20%" }}
            className="mt-2"
            variant="secondary"
            onClick={() => setShowSettingsModal(false)}
          >
            Close
          </Button>
          {/* </Row> */}
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  );
}

const Padel = ({
  firstToGames,
  setFirstToGames,
  totalNumberOfGames,
  setTotalNumberOfGames,
  isGoldenPoint,
  setIsGoldenPoint,
  updateFB,
}) => {
  const t = useTranslations();
  const radios = [
    { name: "4", value: "4" },
    { name: "6", value: "6" },
    { name: "8", value: "8" },
    { name: "9", value: "9" },
  ];

  const radioGames = [
    { name: "1", value: "1" },
    { name: "3", value: "3" },
    { name: "5", value: "5" },
    { name: "7", value: "7" },
  ];

  return (
    <>
      <Row>
        <Col className="d-flex align-items-middle  flex-column">
          <small>{t("noun:first-to-points")}</small>
          <ToggleButtons
            variant={firstToGames?.toString()}
            radios={radios}
            setVariant={(val) => setFirstToGames(parseInt(val))}
          />
        </Col>
        <Col className="d-flex align-items-middle flex-column">
          <small>{t("noun:best-of-games")}</small>
          <ToggleButtons
            variant={totalNumberOfGames?.toString()}
            setVariant={(val) => setTotalNumberOfGames(parseInt(val))}
            radios={radioGames}
          />
        </Col>
        <Col>
          <Form.Check
            checked={isGoldenPoint}
            onChange={() =>
              setIsGoldenPoint((prev) => {
                let temp = !prev;

                updateFB({ isGoldenPoint: temp });

                return temp;
              })
            }
            type="switch"
            id="custom-switch"
            label={t("noun:golden-point")}
          />
        </Col>
      </Row>
    </>
  );
};

export default TennisSettingsModal;
